import React, { Component } from 'react'
import {graphql} from "gatsby"
import SEO from "../components/seo"
import { BgImage } from 'gbimage-bridge';
import { getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"


class Contact extends Component {
  constructor(props) {
    super(props)


  }




    render() {
      return (
    <div className="homeoverflow min-h-screen max-h-full">
    <SEO title="Home" metaImage={this.props.data.strapiContactPage.Header_Image} description="Contact for wild swimm tours bookings on the River Wye" title="Contact for bookings"/>
<main role="main" className="pt-20 h-full">
  <BgImage image={getImage(this.props.data.strapiContactPage.Header_Image.localFile)} Tag="div" className="min-h-screen max-h-full  w-100 ">

    <div className="md:pl-20 pl-10 pt-20 min-h-screen max-h-full bg-DarkGreenery bg-opacity-80">

      <h1 className=" text-white lg:text-14xl md:text-10xl text-5xl leading-vtight " >{this.props.data.strapiContactPage.title}</h1>
      <div className="flex">
      <div className="text-white text-xl md:w-4/6 w-5/6 md:mt-10 mt-10 mb-10 ">
      <ReactMarkdown source={this.props.data.strapiContactPage.Content} escapeHtml={false} linkTarget='_blank'/>
      <div className="flex md:flex-row flex-col">
      <a className="md:w-1/3 w-full mt-10 md:mb-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white text-center text-white p-2 pt-2.5 font-body lg:text-2xl text-base transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer md:mr-5" href={`tel:+44${this.props.data.strapiContactPage.PhoneNo.substring(1).replace(/ /g, "")}`}>Call us</a>
      <a className="md:w-1/3 w-full md:mt-10 md:mb-5 mt-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white text-center text-white p-2 pt-2.5 font-body lg:text-2xl text-base transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer md:ml-5 md:mr-5" href={`mailto:${this.props.data.strapiContactPage.email}?subject=Wyled Swim Enquiry`}>Email us</a>
      <a className="md:w-1/3 w-full md:mt-10 md:mb-5 mt-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white text-center text-white p-2 pt-2.5 font-body lg:text-2xl text-base transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer md:ml-5" href={`https://wa.me/44${this.props.data.strapiContactPage.PhoneNo.substring(1).replace(/ /g, "")}`}>Message us</a>
      </div>
        </div>
      </div>


    </div>


  </BgImage>


</main>


    </div>

  )
}
}

export default Contact;
export const pageQuery = graphql`
  query ScrollContactQuery {
    strapiContactPage {
      title
      Content
      email
      PhoneNo
      Header_Image {
         localFile {
           childImageSharp {
             gatsbyImageData
           }
         }
       }
      }
    }


`
